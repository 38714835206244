// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconUsers, IconDashboard, IconDeviceAnalytics } from '@tabler/icons';

const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconUsers
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

export const MerchantDashboard = {
    id: 'dashboard',
    title: <FormattedMessage id="dashboard" />,
    icon: icons.IconDashboard,
    type: 'group',
    children: [
        {
            id: 'default',
            title: <FormattedMessage id="default" />,
            type: 'item',
            url: '/dashboard/default',
            icon: icons.IconDashboard,
            breadcrumbs: false
        }
        // {
        //     id: 'user',
        //     title: <FormattedMessage id="users" />,
        //     type: 'item',
        //     url: '/dashboard/user',
        //     icon: icons.IconUsers,
        //     breadcrumbs: false
        // }
    ]
};

export const MerchantUserDashboard = {
    id: 'dashboard',
    title: <FormattedMessage id="dashboard" />,
    icon: icons.IconDashboard,
    type: 'group',
    children: [
        {
            id: 'default',
            title: <FormattedMessage id="default" />,
            type: 'item',
            url: '/dashboard/default',
            icon: icons.IconDashboard,
            breadcrumbs: false
        }
    ]
};
