import { Navigate, useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import useAuth from 'hooks/useAuth';
import SubMainRoutes from './SubMainRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const { user } = useAuth();

    return useRoutes([
        { path: '/', element: <Navigate to="/login" /> },
        LoginRoutes,
        user?.role === 'MERCHANT_USER' ? SubMainRoutes : MainRoutes
    ]);
}
