/**
 * axios setup to use mock service
 */

import axios from 'axios';

const axiosServices = axios.create({ baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3010/' });

// interceptor for http
axiosServices.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error?.response?.status === 401) {
            if (!window.location.href?.includes('login')) {
                window.location.href = '/login';
            }
            return Promise.reject((error.response && error.response?.data) || 'Wrong Services');
        }

        return Promise.reject((error.response && error.response?.data) || 'Wrong Services');
    }
);

export default axiosServices;
