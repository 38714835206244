import * as React from 'react';

// material-ui
import {
    Box,
    CardContent,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Fab,
    Grid,
    IconButton,
    InputAdornment,
    Slide,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Tooltip,
    Typography,
    MenuItem
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

// project imports
import CreateMerchant from './CreateUser';
import MainCard from 'ui-component/cards/MainCard';
import { useDispatch } from 'store';

// assets
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/AddTwoTone';
import { CopyAll, Edit, VpnKey } from '@mui/icons-material';
import axiosServices from 'utils/axios';
import { fetchRoles, fetchUsers } from 'utils/api';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import ConfirmationModal from 'ui-component/Modal';
import TableHeader from 'ui-component/TableHead';
import Loader from 'ui-component/Loader';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ROLE_SUPERADMIN } from 'constant';
import useAuth from 'hooks/useAuth';

// table header options
const headCells = [
    {
        id: 'username',
        numeric: false,
        label: 'Username',
        align: 'center'
    },
    {
        id: 'name',
        numeric: false,
        label: 'Name',
        align: 'left'
    },
    {
        id: 'email',
        numeric: false,
        label: 'Email',
        align: 'left'
    },
    {
        id: 'Phone',
        numeric: false,
        label: 'phone',
        align: 'left'
    },
    {
        id: 'Active',
        numeric: false,
        label: 'Active',
        align: 'left'
    },
    {
        id: 'createdAt',
        numeric: false,
        label: 'Created on',
        align: 'left'
    }
];

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

// ==============================|| USER LIST ||============================== //

const User = () => {
    const dispatch = useDispatch();
    const { user } = useAuth();

    // show a right sidebar when clicked on new product
    const [open, setOpen] = React.useState(false);
    const [resetOpen, setResetOpen] = React.useState(false);
    const [roleOpen, setRoleOpen] = React.useState(false);
    const [newPass, setNewPass] = React.useState(false);
    const [selectedMerchant, setSelectedMerchant] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [search, setSearch] = React.useState('');
    const [rows, setRows] = React.useState([]);
    const [allRows, setAllRows] = React.useState([]);

    const [copy, setCopy] = React.useState(false);
    const [selectedUser, setSelectedUser] = React.useState(false);

    const [selectedRole, setSelectedRole] = React.useState('');

    React.useEffect(() => {
        axiosServices('getMerchantUserCreationStatus').then((r) => {
            if (!r?.data?.data) {
                window.location.href = '/';
            }
        });
    }, []);

    React.useEffect(() => {
        if (copy) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Copied!',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            dispatch(closeSnackbar);
            setCopy(false);
        }
    }, [copy]);

    const fetchUsersCall = async () => {
        setLoading(true);
        try {
            const result = await fetchUsers();
            setLoading(false);
            setAllRows(result?.data?.merchantUserList || []);
            setRows(result?.data?.merchantUserList || []);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    React.useEffect(() => {
        fetchUsersCall();
    }, []);

    const handleClickOpenDialog = () => {
        setOpen(true);
    };
    const handleCloseDialog = () => {
        setRoleOpen(false);
        setOpen(false);
        setNewPass(false);
        setResetOpen(false);
        fetchUsersCall();
        setSelectedRole('');
    };

    const handleSearch = (event) => {
        const newString = event?.target.value;
        console.log(newString);
        setSearch(newString || '');

        if (newString) {
            const newRows = rows.filter((row) => {
                let matches = true;

                const properties = ['username', 'email', 'phone', 'firstName'];
                let containsQuery = false;

                properties.forEach((property) => {
                    if (row[property]?.toString().toLowerCase().includes(newString?.toString().toLowerCase())) {
                        containsQuery = true;
                    }
                });

                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
            setRows(newRows);
        } else {
            setRows(allRows);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target.value, 10));
        setPage(0);
    };

    const deleteMerchant = () => {
        setLoading(true);
        axiosServices
            .delete(`deleteMerchant/${selectedMerchant?.merchantId}`)
            .then((res) => {
                setLoading(false);
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'User Deleted',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: false
                    })
                );
                fetchUsersCall();
                dispatch(closeSnackbar);
                setSelectedMerchant(false);
            })
            .catch((e) => {
                setLoading(false);
                dispatch(
                    openSnackbar({
                        open: true,
                        message: e?.message || 'Error while deleting user',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
                dispatch(closeSnackbar);
            });
    };

    const handleResetPassword = () => {
        setLoading(true);
        axiosServices
            .post(`resetPassword?id=${resetOpen?.id}`)
            .then((r) => {
                setLoading(false);
                setResetOpen(false);
                setNewPass(r.data);
            })
            .catch((e) => {
                setLoading(false);
                dispatch(
                    openSnackbar({
                        open: true,
                        message: e?.message || 'Error while resetting password',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
                dispatch(closeSnackbar);
            });
    };

    const handleDisable = () => {
        setLoading(true);
        axiosServices
            .put(`updateMerchantUserByMerchant`, { ...selectedUser, isMerchantUserActive: !selectedUser?.isMerchantUserActive })
            .then((res) => {
                setLoading(false);
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Success',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: false
                    })
                );
                fetchUsersCall();
                dispatch(closeSnackbar);
                setSelectedUser(false);
            })
            .catch((e) => {
                setLoading(false);
                dispatch(
                    openSnackbar({
                        open: true,
                        message: e?.message || 'Error',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
                dispatch(closeSnackbar);
            });
    };

    const handleUpdateRole = () => {
        if (selectedRole) {
            setLoading(true);
            axiosServices
                .post(`changeRole`, {
                    userId: roleOpen?.id,
                    roleChange: selectedRole
                })
                .then((res) => {
                    setLoading(false);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Success',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: false
                        })
                    );
                    handleCloseDialog();
                    dispatch(closeSnackbar);
                })
                .catch((e) => {
                    setLoading(false);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: e?.message || 'Error',
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: true
                        })
                    );
                    dispatch(closeSnackbar);
                });
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Please select role to update',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
    };
    return (
        <MainCard title="User List" content={false}>
            {loading && <Loader />}
            <CardContent>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                )
                            }}
                            onChange={handleSearch}
                            placeholder="Search User"
                            value={search}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
                        <Tooltip title="Add User">
                            <Fab
                                color="primary"
                                size="small"
                                onClick={handleClickOpenDialog}
                                sx={{ boxShadow: 'none', ml: 1, width: 32, height: 32, minHeight: 32 }}
                            >
                                <AddIcon fontSize="small" />
                            </Fab>
                        </Tooltip>
                        <CreateMerchant open={open} handleCloseDialog={handleCloseDialog} />
                    </Grid>
                </Grid>
            </CardContent>

            <ConfirmationModal
                loading={loading}
                open={resetOpen}
                title="Reset Password"
                subTitle="Are you sure, you want to reset password"
                handleClose={() => setResetOpen(false)}
                handleSubmit={handleResetPassword}
            />

            {/* table */}
            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <TableHeader columns={headCells} />
                    <TableBody>
                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                            /** Make sure no display bugs if row isn't an OrderData object */
                            if (typeof row === 'number') return null;
                            return (
                                <TableRow hover>
                                    <TableCell sx={{ minWidth: 120 }} align="center">
                                        {row.username}
                                    </TableCell>
                                    <TableCell sx={{ minWidth: 120 }}>{`${row.firstName || ''}  ${row.lastName || ''}`}</TableCell>
                                    <TableCell>{row.email}</TableCell>
                                    <TableCell>{row.phone}</TableCell>
                                    <TableCell
                                        onClick={() => setSelectedUser(row)}
                                        style={{
                                            color: row.isMerchantUserActive ? '#38b6fd' : 'red',
                                            fontWeight: 'bold',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        {row.isMerchantUserActive ? 'Yes' : 'No'}
                                    </TableCell>
                                    <TableCell>{row.createdAt ? new Date(row.createdAt).toLocaleString() : ''}</TableCell>
                                    <TableCell align="center">
                                        {/* <Stack direction="row" justifyContent="center" alignItems="center">
                                            <Tooltip placement="top" title="Reset Password">
                                                <IconButton color="primary" aria-label="delete" size="large">
                                                    <VpnKey onClick={() => setResetOpen(row)} sx={{ fontSize: '1.1rem' }} />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack> */}
                                        <Stack direction="row" justifyContent="center" alignItems="center">
                                            <Tooltip placement="top" title="Reset Password">
                                                <IconButton color="primary" aria-label="delete" size="large">
                                                    <Edit onClick={() => setOpen(row)} sx={{ fontSize: '1.1rem' }} />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        {/* {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 53 * emptyRows
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )} */}
                    </TableBody>
                </Table>
            </TableContainer>

            <ConfirmationModal
                loading={loading}
                open={selectedMerchant}
                title="Delete User"
                subTitle="Are you sure, you want to delete selected user"
                handleClose={() => setSelectedMerchant(false)}
                handleSubmit={deleteMerchant}
            />

            <ConfirmationModal
                loading={loading}
                open={selectedUser}
                title={`${!selectedUser?.isMerchantUserActive ? 'Enable User' : 'Disable User'}`}
                subTitle="Are you sure, you want to continue"
                handleClose={() => setSelectedUser(false)}
                handleSubmit={handleDisable}
            />

            <Dialog
                open={newPass}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-slide-title1"
                aria-describedby="alert-dialog-slide-description1"
            >
                {newPass && (
                    <>
                        <DialogTitle id="alert-dialog-slide-title1">New Password</DialogTitle>
                        <DialogContent style={{ display: 'flex', alignItems: 'center' }}>
                            <DialogContentText id="alert-dialog-slide-description1">
                                <Typography style={{ marginRight: 10 }} variant="body2" component="span">
                                    {newPass}
                                </Typography>

                                <CopyToClipboard onCopy={() => setCopy(true)} text={newPass}>
                                    <CopyAll style={{ fontSize: 20, cursor: 'pointer', marginLeft: 3 }} />
                                </CopyToClipboard>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ pr: 2.5 }}>
                            <Button variant="contained" size="small" onClick={handleCloseDialog}>
                                Ok
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>

            {/* table pagination */}
            <TablePagination
                rowsPerPageOptions={[50, 100, 200]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </MainCard>
    );
};

export default User;
