import axios from 'utils/axios';

export const fetchMerchants = async () => {
    const response = await axios.get('getMerchants');
    return response.data.data.merchantList;
};

export const getAllBanksName = async () => {
    const response = await axios.get('getBankNamesList');
    return response.data.data.banks;
};

export const fetchRoles = async () => {
    const response = await axios.get('getRoles');
    return response.data;
};

export const fetchUsers = async () => {
    const response = await axios.get('getMerchantUserByMerchant');
    return response.data;
};

export const getLogs = async () => {
    const response = await axios.get('logs');
    return response.data;
};

export const fetchMerchantDetails = async (id) => {
    const response = await axios.get(`getMerchant/${id}`);
    return response.data.data;
};

export const fetchMerchantBanks = async (id) => {
    const response = await axios.get(`get/bank/merchant/${id}`);
    return response.data;
};

export const fetchSsTransactions = async (page, size, startDate, endDate) => {
    const response = await axios.get(`getTransactions?pageIndex=${page}&pageSize=${size}&startDate=${startDate}&endDate=${endDate}`);
    return response.data;
};

export const fetchSsTransactionsById = async (id, payId) => {
    const response = await axios.get(`getSSTxn/${payId}/${id}`);
    return response.data;
};

export const fetchRemarks = async () => {
    const response = await axios.get(`getRemarks`);
    return response.data;
};

export const getFileReports = async (page, size, startDate, endDate) => {
    const response = await axios.get(
        `getPayoutProcessedFileReport?page=${page}&pageSize=${size}&startDate=${startDate}&endDate=${endDate}`
    );
    return response.data;
};

export const getReports = async (page, size, startDate, endDate) => {
    const response = await axios.get(`getReport?page=${page}&pageSize=${size}&startDate=${startDate}&endDate=${endDate}`);
    return response.data;
};

export const fetchStatsByMerchant = async (id) => {
    const response = await axios.get(`getDashboardForMerchant/${id}`);
    return response.data;
};

export const fetchSettlementRequests = async (page, size, startDate, endDate, merchantId) => {
    const response = await axios.get(
        `settle/merchant/${merchantId}?page=${page}&pageSize=${size}&startDate=${startDate}&endDate=${endDate}`
    );
    return response.data;
};

export const fetchPayoutRequests = async (page, size, startDate, endDate, merchantId) => {
    const response = await axios.get(
        `payout/getAllPayoutRequest/${merchantId}?page=${page}&pageSize=${size}&startDate=${startDate}&endDate=${endDate}`
    );
    return response.data;
};

export const fetchSSTByFilter = async (page, size, payload) => {
    const response = await axios.post(`newPayinFilterForMerchant?pageIndex=${page}&pageSize=${size}`, payload);
    return response.data;
};

export const fetchPayoutTransactions = async (page, size, startDate, endDate) => {
    const response = await axios.get(`getPayoutTransactions?pageIndex=${page}&pageSize=${size}&startDate=${startDate}&endDate=${endDate}`);
    return response.data;
};

export const fetchPayoutTransactionsByFilter = async (page, size, payload) => {
    const response = await axios.post(`newPayoutFilterForAffiliate?pageIndex=${page}&pageSize=${size}`, payload);
    return response.data;
};
