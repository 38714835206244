import { MerchantDashboard, MerchantUserDashboard } from './dashboard';
import { MerchantPayment, MerchantUserPayment } from './payment';
import { MerchantOther, MerchantUserOther } from './other';

// ==============================|| MENU ITEMS ||============================== //

const DefaultMenuItems = {
    items: [MerchantDashboard, MerchantPayment, MerchantOther]
};

export const MerchantMenuItems = {
    items: [MerchantDashboard, MerchantPayment, MerchantOther]
};

export const MerchantUserMenuItems = {
    items: [MerchantUserDashboard, MerchantUserPayment, MerchantUserOther]
};

export default DefaultMenuItems;
