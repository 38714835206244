// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconArrowDownLeft, IconArrowUpRight, IconAdjustments } from '@tabler/icons';

const icons = {
    IconArrowDownLeft,
    IconArrowUpRight,
    IconAdjustments
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

export const MerchantPayment = {
    id: 'payin & payout',
    title: <FormattedMessage id="payin & payout" />,
    icon: icons.IconDashboard,
    type: 'group',
    children: [
        {
            id: 'payin',
            title: <FormattedMessage id="Payin" />,
            type: 'item',
            url: '/payment/payin',
            icon: icons.IconArrowDownLeft,
            breadcrumbs: false
        },
        {
            id: 'payout',
            title: <FormattedMessage id="payout" />,
            type: 'item',
            url: '/payment/payout',
            icon: icons.IconArrowUpRight,
            breadcrumbs: false
        }

        // {
        //     id: 'refunds',
        //     title: <FormattedMessage id="Refunds" />,
        //     type: 'item',
        //     url: '/payment/refunds',
        //     icon: icons.IconAdjustments,
        //     breadcrumbs: false
        // }
    ]
};

export const MerchantUserPayment = {
    id: 'payin & payout',
    title: <FormattedMessage id="payin & payout" />,
    icon: icons.IconDashboard,
    type: 'group',
    children: [
        {
            id: 'payin',
            title: <FormattedMessage id="Payin" />,
            type: 'item',
            url: '/payment/payin',
            icon: icons.IconArrowDownLeft,
            breadcrumbs: false
        },
        {
            id: 'payout',
            title: <FormattedMessage id="payout" />,
            type: 'item',
            url: '/payment/payout',
            icon: icons.IconArrowUpRight,
            breadcrumbs: false
        }

        // {
        //     id: 'refunds',
        //     title: <FormattedMessage id="Refunds" />,
        //     type: 'item',
        //     url: '/payment/refunds',
        //     icon: icons.IconAdjustments,
        //     breadcrumbs: false
        // }
    ]
};
