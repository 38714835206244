import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';

// material-ui
import {
    MenuItem,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Slide,
    TextField,
    FormControlLabel,
    Switch
} from '@mui/material';

import { gridSpacing } from 'store/constant';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import axiosServices from 'utils/axios';

// animation
const Transition = forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);

// ==============================|| User ADD DIALOG ||============================== //

const CreateUser = ({ open, handleCloseDialog }) => {
    const dispatch = useDispatch();
    const [details, setDetails] = useState({
        isSinglePayoutEnable: false
    });

    const validationSchema = yup.object({
        firstName: yup.string().required('Required Field'),
        lastName: yup.string().required('Required Field'),
        username: yup.string().required('Required Field'),
        mobile: yup.string().required('Required Field'),
        password: open?.id ? null : yup.string().required('Required Field').min(6),
        email: yup.string().email().required('Required Field')
    });

    const formik = useFormik({
        validateOnChange: true,
        validateOnBlur: true,
        enableReinitialize: true,
        initialValues: details,
        validationSchema,
        onSubmit: (data, { resetForm }) => {
            if (open?.id) {
                axiosServices
                    .put('updateMerchantUserByMerchant', data)
                    .then((res) => {
                        resetForm();
                        handleCloseDialog();
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: 'Submit Success',
                                variant: 'alert',
                                alert: {
                                    color: 'success'
                                },
                                close: false
                            })
                        );
                    })
                    .catch((e) => {
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: e?.message || e?.message || 'Error while saving details',
                                variant: 'alert',
                                alert: {
                                    color: 'error'
                                },
                                close: true
                            })
                        );
                        dispatch(closeSnackbar);
                    });
            } else {
                axiosServices
                    .post('add-merchant-user', data)
                    .then((res) => {
                        resetForm();
                        handleCloseDialog();
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: 'Submit Success',
                                variant: 'alert',
                                alert: {
                                    color: 'success'
                                },
                                close: false
                            })
                        );
                    })
                    .catch((e) => {
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: e?.message || e?.message || 'Error while saving details',
                                variant: 'alert',
                                alert: {
                                    color: 'error'
                                },
                                close: true
                            })
                        );
                        dispatch(closeSnackbar);
                    });
            }
        }
    });

    useEffect(() => {
        if (open?.merchantId) {
            open.mobile = open?.phone;
            setDetails(open);
            formik.setFieldValue('mobile', details?.phone);
        }
    }, [open]);

    console.log('de', details);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            // sx={{
            //     '&>div:nth-of-type(3)': {
            //         justifyContent: 'flex-end',
            //         '&>div': {
            //             m: 0,
            //             borderRadius: '0px',
            //             maxWidth: 450,
            //             maxHeight: '100%'
            //         }
            //     }
            // }}
        >
            {open && (
                <form onSubmit={formik.handleSubmit}>
                    <DialogTitle>{open?.merchantId ? 'Edit User' : 'Add User'}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={gridSpacing} sx={{ mt: 0.25 }}>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="firstName"
                                    name="firstName"
                                    label="First Name"
                                    value={formik.values.firstName}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.firstName)}
                                    helperText={formik.errors.firstName}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="lastName"
                                    name="lastName"
                                    label="Last Name"
                                    value={formik.values.lastName}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.lastName)}
                                    helperText={formik.errors.lastName}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="username"
                                    name="username"
                                    label="Username"
                                    disabled={open?.id}
                                    value={formik.values.username}
                                    onChange={formik.handleChange}
                                    error={formik.errors.username}
                                    helperText={formik.errors.username}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="email"
                                    type="email"
                                    label="Email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.email)}
                                    helperText={formik.errors.email}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="mobile"
                                    label="Mobile"
                                    type="number"
                                    value={formik.values.mobile}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.mobile)}
                                    helperText={formik.errors.mobile}
                                />
                            </Grid>

                            {open?.id ? null : (
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="password"
                                        username="password"
                                        label="Passsword"
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        error={Boolean(formik.errors.password)}
                                        helperText={formik.errors.password}
                                    />
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <FormControlLabel
                                    style={{ display: 'flex', justifyContent: 'center' }}
                                    labelPlacement="start"
                                    control={
                                        <Switch
                                            size="small"
                                            onChange={(e) => {
                                                formik.setFieldValue('isSinglePayoutEnable', e.target.checked);
                                            }}
                                            checked={formik.values.isSinglePayoutEnable}
                                        />
                                    }
                                    label="Single Payout Enable"
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <AnimateButton>
                            <Button type="submit" variant="contained">
                                Save
                            </Button>
                        </AnimateButton>
                        <Button
                            variant="text"
                            color="error"
                            onClick={() => {
                                formik.resetForm();
                                setDetails({});
                                handleCloseDialog();
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </form>
            )}
        </Dialog>
    );
};

CreateUser.propTypes = {
    open: PropTypes.bool,
    handleCloseDialog: PropTypes.func
};

export default CreateUser;
